export enum AutotestId {
  PROFILE_AVATAR_BUTTON = "profile-avatar-button",
  PROFILE_BIND_PHONE_NUMBER_BUTTON = "profile-bind-phone-number-button",
  PROFILE_CHANGE_PASSWORD_BUTTON = "profile-change-password-button",
  PROFILE_SECOND_FACTOR_BUTTON = "profile-second-factor-button",
  PROFILE_API_DOC_BUTTON = "profile-api-doc-button",
  PROFILE_MAX_SESSION_LIMIT_BUTTON = "profile-max-session-limit-button",
  PROFILE_LOGOUT_BUTTON = "profile-logout-button",
  PROFILE_TELEGRAM_EDIT_BUTTON = "profile-telegram-edit-button",
  PROFILE_TELEGRAM_TEXT_FIELD = "profile-telegram-text-field",
  PROFILE_TELEGRAM_VALUE = "profile-telegram-value",
  PROFILE_EMAIL_VALUE = "profile-email-value",

  CHANGE_PASSWORD_CLOSE_BUTTON = "change-password-close-button",
  CHANGE_PASSWORD_CURRENT_PASSWORD_TEXT_FIELD = "change-password-current-password-text-field",
  CHANGE_PASSWORD_NEW_PASSWORD_TEXT_FIELD = "change-password-new-password-text-field",
  CHANGE_PASSWORD_REPEAT_PASSWORD_TEXT_FIELD = "change-password-repeat-password-text-field",
  CHANGE_PASSWORD_SECOND_FACTOR_CODE_TEXT_FIELD = "change-password-second-factor-code-text-field",
  CHANGE_PASSWORD_SAVE_BUTTON = "change-password-save-button",
  CHANGE_PASSWORD_CANCEL_BUTTON = "change-password-cancel-button",

  SECOND_FACTOR_CODE_TEXT_FIELD = "second-factor-code-text-field",
  SECOND_FACTOR_SUBMIT_BUTTON = "second-factor-enable-button",
  SECOND_FACTOR_CLOSE_BUTTON = "second-factor-close-button",
}
