import { Component, Vue, Ref } from "vue-property-decorator";
import { getFullName, checkTelegram, cloneStructured } from "@helpers";
import { AuthActions } from "@store/modules/auth/types";
import {
  ProfileActions,
  ProfileState,
  ProfileGetters,
} from "@store/modules/profile/types";
import {
  authModule,
  profileModule,
  userModule,
  cardModule,
} from "@store/namespaces";
import { Profile } from "@/types/profile";
import { VForm } from "@/types/vuetify";
import { UserGetters } from "@store/modules/user/types";
import { AutotestId } from "@/types/autotests";
import { HtmlElementId } from "@/types/element";
import { Role } from "@/types/role";
import { CardActions } from "@store/modules/card/types";
import { accessFacebookCodeNotifications } from "@config/base";

@Component({
  components: {
    PhoneNumberField: () =>
      import("../../components/PhoneNumberField/PhoneNumberField.vue"),
    FA2Form: () => import("@/components/FA2Form/FA2Form.vue"),
    UpdatePassword: () =>
      import("@/components/UpdatePassword/UpdatePassword.vue"),
    AvatarsDialog: () => import("@/components/AvatarsDialog/AvatarsDialog.vue"),
    ProfilePromocode: () =>
      import("../../components/ProfilePromocode/ProfilePromocode.vue"),
    PromocodesTable: () =>
      import("../../components/PromocodesTable/PromocodesTable.vue"),
  },
})
export default class ProfileMixin extends Vue {
  @Ref("form") private readonly formRef!: VForm;

  @profileModule.Getter("profileTeamleadEmail")
  private readonly profileTeamleadEmailGetter!: ProfileGetters["profileTeamleadEmail"];
  @profileModule.Getter("profileEmail")
  private readonly profileEmailGetter!: ProfileGetters["profileEmail"];
  @profileModule.Getter("userHasRole")
  private readonly userHasRoleGetter!: ProfileGetters["userHasRole"];
  @profileModule.State("profile")
  private readonly profileState!: ProfileState["profile"];
  @profileModule.Action("updateProfile")
  private readonly updateProfileAction!: ProfileActions["updateProfile"];
  @profileModule.Getter("profileAvatar")
  private readonly profileAvatarGetter!: ProfileGetters["profileAvatar"];
  @profileModule.Action("fetchProfile")
  private readonly fetchProfileAction!: ProfileActions["fetchProfile"];

  @userModule.Getter("userIsGhost")
  private readonly userIsGhostGetter!: UserGetters["userIsGhost"];
  @authModule.Action private readonly logOut!: AuthActions["logOut"];
  @cardModule.Action("fetchCards")
  protected readonly fetchCardsAction!: CardActions["fetchCards"];

  private activeEditField: string | null = null;

  private form: Profile | null = null;

  private updatingProfile = false;
  private dialog2FA = false;
  private dialogUpdatePassword = false;
  private dialogAvatarsDialog = false;
  private dialogHistoryPromocodes = false;

  private get htmlElementId() {
    return {
      profileMaxSessionLimitField: HtmlElementId.profileMaxSessionLimitField,
      profileSecondFactorField: HtmlElementId.profileSecondFactorField,
      profileBindPhoneNumberField: HtmlElementId.profileBindPhoneNumberField,
    };
  }

  private get autoTestId() {
    return {
      avatarButton: AutotestId.PROFILE_AVATAR_BUTTON,
      emailValue: AutotestId.PROFILE_EMAIL_VALUE,
      bindPhoneNumber: AutotestId.PROFILE_BIND_PHONE_NUMBER_BUTTON,
      telegramEditButton: AutotestId.PROFILE_TELEGRAM_EDIT_BUTTON,
      telegramTexField: AutotestId.PROFILE_TELEGRAM_TEXT_FIELD,
      changePasswordButton: AutotestId.PROFILE_CHANGE_PASSWORD_BUTTON,
      secondFactorButton: AutotestId.PROFILE_SECOND_FACTOR_BUTTON,
      apiDocButton: AutotestId.PROFILE_API_DOC_BUTTON,
      maxSessionLimitButton: AutotestId.PROFILE_MAX_SESSION_LIMIT_BUTTON,
      logoutButton: AutotestId.PROFILE_LOGOUT_BUTTON,
      secondFactorDialogCloseButton: AutotestId.SECOND_FACTOR_CLOSE_BUTTON,
      changePasswordCloseButton: AutotestId.CHANGE_PASSWORD_CLOSE_BUTTON,
    };
  }

  private get secondFactorEnabled() {
    return !!this.profileState?.secondFactorEnabled;
  }

  private get canViewApiDoc() {
    return !!this.profileState?.canViewApiDoc;
  }

  private get fullName() {
    return getFullName({
      firstName: this.profileState?.firstName,
      lastName: this.profileState?.lastName,
    });
  }

  private get fieldRules() {
    return {
      telegram: (v: string) =>
        checkTelegram(v) ||
        this.$vuetify.lang.t("$vuetify.errors.invalid_telegram_login"),
    };
  }

  private get formFields() {
    return [
      {
        id: "email",
        key: "email",
        label: "Email",
        icon: "$email",
        canEdit: false,
        autoTestId: {
          value: this.autoTestId.emailValue,
        },
      },
      {
        id: "phone",
        key: "phone",
        label: this.$vuetify.lang.t("$vuetify.fields.phone"),
        icon: "$phone",
        canEdit: !this.userIsGhostGetter,
        autoTestId: {
          button: this.autoTestId.bindPhoneNumber,
        },
        attrs: {
          id: this.htmlElementId.profileBindPhoneNumberField,
        },
      },
      {
        id: "telegram",
        key: "telegram",
        label: "Telegram",
        icon: "$telegram",
        placeholder: "@login",
        rules: [this.fieldRules.telegram],
        canEdit: !this.userIsGhostGetter,
        autoTestId: {
          editButton: this.autoTestId.telegramEditButton,
          textField: this.autoTestId.telegramTexField,
        },
      },
      {
        id: "teamlead",
        key: "teamlead",
        label: "Teamlead",
        icon: "$crown",
        enabled: !!this.profileState?.teamlead,
        canEdit: false,
      },
      {
        id: "password",
        key: "password",
        label: this.$vuetify.lang.t("$vuetify.fields.password"),
        icon: "$lock",
        canEdit: !this.userIsGhostGetter,
        autoTestId: {
          button: this.autoTestId.changePasswordButton,
        },
      },
      {
        id: "secondFactorEnabled",
        key: "secondFactorEnabled",
        label: this.$vuetify.lang.t("$vuetify.fields.auth_2auth"),
        icon: "$userImages",
        canEdit: !this.userIsGhostGetter,
        autoTestId: {
          button: this.autoTestId.secondFactorButton,
        },
        attrs: {
          id: this.htmlElementId.profileSecondFactorField,
        },
      },
      {
        id: "apiDoc",
        key: "apiDoc",
        label: "API-doc",
        icon: "mdi-text-box-multiple-outline",
        canEdit: !this.userIsGhostGetter,
        autoTestId: {
          button: this.autoTestId.apiDocButton,
        },
      },
      {
        id: "maxSessionLimit",
        key: "maxSessionLimit",
        label: this.$vuetify.lang.t(
          "$vuetify.dashboard.limit_per_active_session"
        ),
        canEdit: !this.userIsGhostGetter,
        autoTestId: {
          button: this.autoTestId.maxSessionLimitButton,
        },
        attrs: {
          buttonId: this.htmlElementId.profileMaxSessionLimitField,
        },
        listeners: {
          onChange: () => {
            this.saveChanges();
          },
        },
      },
      {
        id: "canViewTeamCardData",
        key: "canViewTeamCardData",
        label: this.$vuetify.lang.t(
          "$vuetify.dashboard.hide_team_members_cards"
        ),
        enabled: !this.userHasRoleGetter(Role.ROLE_MEDIABUYER),
        canEdit: !this.userIsGhostGetter,
        attrs: {
          trueValue: false,
          falseValue: true,
        },
        listeners: {
          onChange: () => {
            this.saveChanges();
          },
        },
      },
      {
        id: "canViewDecryptedCardData",
        key: "canViewDecryptedCardData",
        label: this.$vuetify.lang.t("$vuetify.dashboard.encrypt_card_data"),
        canEdit: !this.userIsGhostGetter,
        attrs: {
          trueValue: false,
          falseValue: true,
        },
        listeners: {
          onChange: async () => {
            await this.saveChanges();
            this.fetchCardsAction({ forceUpdate: true });
          },
        },
      },
      {
        id: "canViewFacebookCodeNotifications",
        key: "canViewFacebookCodeNotifications",
        label: this.$vuetify.lang.t(
          "$vuetify.dashboard.facebook_code_notifications"
        ),
        canEdit: !this.userIsGhostGetter,
        listeners: {
          onChange: () => {
            this.saveChanges();
          },
        },
        enabled:
          accessFacebookCodeNotifications.includes(this.profileEmailGetter) ||
          accessFacebookCodeNotifications.includes(
            this.profileTeamleadEmailGetter
          ),
      },
    ].filter(({ enabled = true }) => enabled);
  }

  private async cancelChanges() {
    await this.$nextTick();
    this.activeEditField = null;
  }

  private onSaveAvatar(avatarName: string) {
    if (!this.form) return;

    this.form.avatar = avatarName;
    this.saveChanges();
  }

  private onUpdatedPhoneNumber() {
    this.$notify({
      type: "success",
      title: this.$vuetify.lang.t("$vuetify.info.changes_saved"),
    });
    this.fetchProfileAction();
  }

  private toggleDialogHistoryPromocodes(showed: boolean) {
    this.dialogHistoryPromocodes = showed;
  }

  private toggleDialog2FA(showed: boolean) {
    this.dialog2FA = showed;
  }

  private toggleDialogUpdatePassword(showed: boolean) {
    this.dialogUpdatePassword = showed;
  }

  private toggleDialogAvatarsDialog(showed: boolean) {
    this.dialogAvatarsDialog = !this.userIsGhostGetter && showed;
  }

  private isEditFormField(key: keyof Profile) {
    return this.activeEditField === key;
  }

  private async saveChanges(
    form: {
      telegram?: string;
    } = {}
  ) {
    if (this.updatingProfile || !this.formRef.validate()) return;

    this.updatingProfile = true;

    const { telegram = this.form?.telegram } = form;

    try {
      await this.updateProfileAction({ ...this.form, telegram });

      this.updatingProfile = false;
      this.cancelChanges();
      this.$notify({
        type: "success",
        title: this.$vuetify.lang.t("$vuetify.info.changes_saved"),
      });
    } catch (error) {
      this.updatingProfile = false;
    }
  }

  private toggleEditFormField(
    key: keyof Profile,
    edited = !this.isEditFormField(key)
  ) {
    this.activeEditField = edited ? key : null;
  }

  private created() {
    this.$watch(
      () => {
        return this.profileState;
      },
      (profileState) => {
        if (!profileState) return;

        this.form = cloneStructured(profileState);
      },
      {
        immediate: true,
      }
    );
  }
}
